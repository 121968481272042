// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-categorized-page-js": () => import("./../../../src/templates/categorized-page.js" /* webpackChunkName: "component---src-templates-categorized-page-js" */),
  "component---src-templates-fine-print-js": () => import("./../../../src/templates/fine-print.js" /* webpackChunkName: "component---src-templates-fine-print-js" */)
}

